<template>
  <div>
    <vx-card>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <div class="con-vs-alert con-vs-alert-primary con-icon">
            <div class="vs-alert con-icon">
              <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
              Hanya termin yang belum pernah terbayar yang dapat dipilih.
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap justify-end items-center mb-1">
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="search" @keyup="onSearch"/>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

      <!--table-->
      <vs-table
        :class="{ 'animate-pulse': isLoading }"
        :sst="true"
        :data="items"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode">No SPP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama">No Termin</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Status</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">DPP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">PPN</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Total</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">PPh</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Nominal ke Rekanan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)" :disabled="item.status === 'PART PAID' || item.status === 'PAID'">Pilih</vs-button>
            </vs-td>
            <vs-td>{{ item.no_spk }}</vs-td>
            <vs-td>Termin {{ item.no_termin }}</vs-td>
            <vs-td class="">{{ item.status }}</vs-td>
            <vs-td class="text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="text-right">{{ item.ppn_jml | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_total | idr }}</vs-td>
            <vs-td class="text-right">{{ item.pph_jml | idr }}</vs-td>
            <vs-td class="text-right">{{ item.nominal | idr }}</vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right">{{ summary.grandTotalDpp | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right">{{ summary.grandTotalPpn | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right">{{ summary.grandTotal | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right">{{ summary.grandTotalPph | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap font-bold text-sm text-right">{{ summary.grandTotalNominal | idr }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import _ from 'lodash'

export default {
  name: 'SpkTermin',
  components: {},
  props: {
    idSpk: { default: null },
    selectable: { default: false, type: Boolean }
  },
  mounted () {
    this.getData()
  },
  computed: {
    summary () {
      return {
        grandTotalDpp: _.sumBy(this.items, item => item.dpp),
        grandTotalPpn: _.sumBy(this.items, item => item.ppn_jml),
        grandTotal: _.sumBy(this.items, item => item.jml_total),
        grandTotalPph: _.sumBy(this.items, item => item.pph_jml),
        grandTotalNominal: _.sumBy(this.items, item => item.nominal)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      items: []
    }
  },
  methods: {
    getData () {
      this.isLoading = true

      const params = {
        search: this.search
      }
      SpkRepository.getSpkTermin(this.idSpk, params)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
