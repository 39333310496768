<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Tambah SPP Adjust" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <vs-divider class="m-0 mb-2">Detail Termin</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Pilih Termin Yang Akan Dikurangi *</label>
            <div @click="modalSpkTermin.active = true">
              <vx-input-group>
                <vs-input :value="selectedTermin.no_termin ? 'Termin ' + selectedTermin.no_termin : ''" disabled/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tanggal *</label>
            <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Total Nilai <span class="text-xs opacity-50 ml-1">(DPP + PPN)</span></label>
            <v-money class="w-full" v-model="selectedTermin.jml_total" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">DPP</label>
            <v-money class="w-full" v-model="selectedTermin.dpp" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">PPN</label>
            <v-money class="w-full" v-model="selectedTermin.ppn_jml" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">PPh</label>
            <v-money class="w-full" v-model="selectedTermin.pph_jml" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nominal ke Rekanan <span class="text-xs opacity-50 ml-1">(DPP + PPN - PPh)</span></label>
            <v-money class="w-full" v-model="selectedTermin.nominal" disabled/>
          </div>
        </div>

        <vs-divider class="mt-base mt-2 mb-2">Detail Pengurangan Termin</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Total Nilai Dikurangi</label>
            <v-money class="w-full" :value="penguranganTotal" :danger="selectedTermin.id && (selectedTermin.jml_total < penguranganTotal)" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">DPP Dikurangi</label>
            <v-money class="w-full" :value="penguranganDpp" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">PPN Dikurangi</label>
            <v-money class="w-full" :value="penguranganPpn" disabled/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">PPh Dikurangi</label>
            <v-money class="w-full" :value="penguranganPph" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nominal ke Rekanan Dikurangi</label>
            <v-money class="w-full" :value="penguranganNominalKeRekanan" disabled/>
          </div>
        </div>

        <vs-divider class="mt-base">Lainnya</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="text-xs">Files</label>
            <vs-input class="w-full" type="file" multiple accept=".jpeg,.jpg,.png,.pdf" v-model="data.filesTmp" @change="data.files = $event.target.files"/>
          </div>
        </div>
        <div class="vx-row mb-3 mt-5">
          <div class="vx-col w-full">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="vs-alert con-icon">
                <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
                Tandai pekerjaan yang tidak terselesaikan.
              </div>
            </div>
          </div>
        </div>
        <vs-progress v-if="isLoadingGetPekerjaan" indeterminate color="primary" :height="1"/>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full h-72 overflow-auto">
            <vs-table :data="data.listPekerjaan" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap w-24">#</vs-th>
                <vs-th class="whitespace-no-wrap w-24">Check</vs-th>
                <vs-th class="whitespace-no-wrap">No Unit</vs-th>
                <vs-th class="whitespace-no-wrap">Nama Pekerjaan</vs-th>
                <vs-th class="whitespace-no-wrap">Bobot</vs-th>
                <vs-th class="whitespace-no-wrap">Total</vs-th>
                <vs-th class="whitespace-no-wrap">Total Pengurangan</vs-th>
                <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <vs-checkbox size="small" v-model="item.checked"/>
                  </vs-td>
                  <vs-td class="whitespace-no-wrap">{{ item.no_unit || '-' }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ item.nama_item_progress_pekerjaan }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ item.bobot ? item.bobot + '%' : '-' }}</vs-td>
                  <vs-td class="whitespace-no-wrap">{{ (item.total - item.total_pengurangan_adjust) | idr }}</vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <v-money class="w-full" v-model="item.total_pengurangan" :danger="item.total_pengurangan > (item.total - item.total_pengurangan_adjust)" :disabled="!item.checked"/>
                  </vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <vs-input class="w-full" v-model="item.keterangan" :disabled="!item.checked"/>
                  </vs-td>
                </vs-tr>
                <!--footer-->
                <vs-tr>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-left">{{ summary.grandTotal | idr }}</vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-left">{{ summary.grandTotalPengurangan | idr }}</vs-td>
                  <vs-td class="whitespace-no-wrap font-bold text-sm text-right"></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Termin"
                :active="modalSpkTermin.active"
                v-on:update:active="modalSpkTermin.active = $event">
        <SpkTermin v-if="!isFirstActive"
                   :id-spk="$route.params.idSpk"
                   :selectable="true"
                   @selected="onSelectedModalSpkTermin"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SpkAdjustRepository from '@/repositories/proyek/spk-adjust-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'
import VMoney from '@/views/components/v-money/VMoney'
import SpkTermin from '@/views/pages/proyek/spk/tabs-show/adjust/SpkTermin'
import SpkRepository from '@/repositories/proyek/spk-repository'
import { convertToFormData } from '@/utilities/common/global-methods'
import moment from 'moment/moment'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'SpkAdjustAdd',
  props: ['isActive'],
  components: {
    SpkTermin,
    VMoney,
    ValidationErrors,
    flatPickr
  },
  computed: {
    penguranganTotal () {
      return this.summary.grandTotalPengurangan
    },
    persentasePengurangan () {
      const totalTermin = this.selectedTermin.id ? this.selectedTermin.jml_total : 0
      return (this.penguranganTotal / totalTermin) * 100
    },
    penguranganDpp () {
      const dpp = this.selectedTermin.id ? this.selectedTermin.dpp : 0
      return dpp * (this.persentasePengurangan / 100)
    },
    penguranganPpn () {
      const ppn = this.selectedTermin.id ? this.selectedTermin.ppn_jml : 0
      return ppn * (this.persentasePengurangan / 100)
    },
    penguranganPph () {
      const pph = this.selectedTermin.id ? this.selectedTermin.pph_jml : 0
      return pph * (this.persentasePengurangan / 100)
    },
    penguranganNominalKeRekanan () {
      const nominal = this.selectedTermin.id ? this.selectedTermin.nominal : 0
      return nominal * (this.persentasePengurangan / 100)
    },
    summary () {
      return {
        grandTotal: _.sumBy(this.data.listPekerjaan, item => (item.total - item.total_pengurangan_adjust)),
        grandTotalPengurangan: _.sumBy(this.data.listPekerjaan, item => item.total_pengurangan)
      }
    }
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getSpkPekerjaan()
        this.isFirstActive = false
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isLoadingGetPekerjaan: false,
      errors: null,
      modalSpkTermin: {
        active: false
      },
      selectedTermin: {},
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        keterangan: null,
        listPekerjaan: []
      }
    }
  },
  methods: {
    onSelectedModalSpkTermin (item) {
      this.selectedTermin = item
      this.modalSpkTermin.active = false
    },

    getSpkPekerjaan () {
      this.isLoadingGetPekerjaan = true

      SpkRepository.getSpkPekerjaan(this.$route.params.idSpk)
        .then(response => {
          this.data.listPekerjaan = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingGetPekerjaan = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData(this.buildParams())
      SpkAdjustRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      const checkedPekerjaan = _.filter(this.data.listPekerjaan, item => item.checked)
      return {
        id_spk: this.$route.params.idSpk,
        id_spk_d: this.selectedTermin.id,
        tgl: this.data.tgl,
        total_termin: this.selectedTermin.jml_total,
        persentase_pengurangan: this.persentasePengurangan,
        pengurangan_total: this.penguranganTotal,
        pengurangan_dpp: this.penguranganDpp,
        pengurangan_ppn: this.penguranganPpn,
        pengurangan_pph: this.penguranganPph,
        pengurangan_nominal: this.penguranganNominalKeRekanan,
        keterangan: this.data.keterangan,
        files: this.data.files,
        listPekerjaan: _.map(checkedPekerjaan, item => {
          return {
            id: item.id,
            keterangan: item.keterangan,
            total: (item.total - item.total_pengurangan_adjust),
            total_pengurangan: item.total_pengurangan
          }
        })
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
